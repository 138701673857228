import { render, staticRenderFns } from "./ProcedureFormPacote.vue?vue&type=template&id=568f89dc&scoped=true"
import script from "./ProcedureFormPacote.vue?vue&type=script&lang=js"
export * from "./ProcedureFormPacote.vue?vue&type=script&lang=js"
import style0 from "./ProcedureFormPacote.vue?vue&type=style&index=0&id=568f89dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568f89dc",
  null
  
)

export default component.exports